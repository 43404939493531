import { Box, Button, Flex, Text } from "@chakra-ui/react";
import { SignupFormLayout } from "./SignupFormLayout";
import { Gap } from "../../../mini-lib/gap/Gap";
import { TextInput } from "../../../mini-lib";
import { Formik } from "formik";
import { buildFormikProps } from "../../../mini-lib/form/utils";
import React, { useState } from "react";
import * as Yup from "yup";
import { useDispatch } from "react-redux";
import { APICreateSalonLara } from "../../../data/salon/interfaces";
import { UseQueryParams } from "../../../mini-lib/utils/basic";
import { UseBaseApiParams } from "../../../core/UseBaseApiParams";
import { dispatchLaraCreateSalon } from "../../../data/salon/api";
import { generatePath, Redirect } from "react-router-dom";

import { ROUTES } from "../../../appRoutes";
import { UseGeneratePathWithQPS } from "../../../mini-lib/router/router-utils";
import { LoggedInUserSalonContext } from "../../../data/user/interfaces";
import { addDays, format } from "date-fns";
import { Loading } from "../../../mini-lib/loading/Loading";

export const SignupCreateSalonPage = () => {
  return (
    <SignupFormLayout stepNumber={2} totalSteps={4}>
      <Flex justify='center' align='center' direction='column'>
        <Gap/>
        <Text variant='title2-desktop-bold'>What is your salon name?</Text>
        <Gap s='32px'/>
        <CreateSalonForm/>
      </Flex>
    </SignupFormLayout>
  )
}

interface FormProps {
  salonName: string
  timezone: string
  // phone: string
}

const CreateSalonForm = () => {
  const dispatch = useDispatch()
  const queryParams = UseQueryParams()
  const dev = queryParams.get('dev')
  const subscriptionOption = queryParams.get('type')
  const planOption = queryParams.get('plan')

  const { user } = UseBaseApiParams()
  const [submitted, setSubmitted] = useState(false)
  const initialTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone

  const initialValues: FormProps = dev ? {
    salonName: 'salon name',
    timezone: initialTimezone,
    // phone: '306 250 6924'
  } : {
    salonName: '',
    timezone: initialTimezone,
    // phone: null
  }
  const validationSchema = Yup.object({
    salonName: Yup.string().required('Salon name is required'),
    timezone: Yup.string(),
    // phone: Yup.string().required('Phone is required'),
  })
  const submitClicked = ( values: FormProps ) => {
    if (!submitted) {
      setSubmitted(true)

      const startDateString = format(new Date(), 'yyyy-MM-dd')
      const expiryDate = addDays(new Date(), 14)
      const expiryDateString = format(expiryDate, 'yyyy-MM-dd')
      const body: APICreateSalonLara = {
        name: values.salonName,
        timezone: values.timezone,
        // phone: values.phone,
        user_id: user.userId,
      }
      // if they are not going to be paid set up a trial for them
      if (subscriptionOption !== 'pay') {
        body['trial_start_date'] = startDateString
        body['trial_expiry_date'] = expiryDateString
      }

      dispatch(dispatchLaraCreateSalon({ token: user.token, request: body }))
    }
  }
  const salon = user.currentSalonContext

  const redirectUrl = buildRedirectUrl(salon, subscriptionOption, planOption)
  if (salon && !dev) {
    return <Redirect to={redirectUrl}/>
  }
  return (
    <>
      <Box w='100%'>
        <Formik onSubmit={submitClicked} initialValues={initialValues} validationSchema={validationSchema}>
          {( { handleSubmit, resetForm, ...formikParams } ) => {

            const salonNameParams = buildFormikProps({ name: 'salonName', formikParams })
            return (
              <>

                <Box p='24px'>
                  <TextInput
                    variant='material'
                    label="Salon Name"
                    value={salonNameParams.value}
                    errorText={salonNameParams.errorText}
                    onBlur={salonNameParams.onBlur}
                    onChange={salonNameParams.onChange}
                  />
                  <Gap/>
                </Box>


                <Box p='0 12px'>
                  <Button variant='round-large' colorScheme='brand.midnight' w='100%' onClick={() => handleSubmit()} cursor={submitted ? 'auto' : 'pointer'}>
                    {submitted ? <Loading variant='clear'/> : 'Continue'}
                  </Button>
                </Box>
                <Gap s='32px'/>

              </>

            )
          }}
        </Formik>

      </Box>
    </>
  )
}

// when coming from the marketing site there are a couple different query params that might be on the url
//
// if they exist use them to redirect the user appropriately
// if the params dont exist default to going to the salon description trial page
//
//
export const buildRedirectUrl = (
  salonContext: LoggedInUserSalonContext | null,
  subscriptionOption: 'pay' | 'trial' | null,
  planOption: 'team-monthly' | 'team-yearly' | 'solo-monthly' | 'solo-yearly' | null
): { pathname: string; search: any } => {
  let path = ROUTES.signupV2Description
  if (salonContext && subscriptionOption === 'pay' && !!planOption) {
   path = generatePath(ROUTES.checkoutScale, {salonId: salonContext.salonId} )
  }
  if (salonContext && subscriptionOption === 'pay' && !planOption) {
   path = generatePath(ROUTES.checkoutSubscription, {salonId: salonContext.salonId} )
  }
  return UseGeneratePathWithQPS({ pathname: path })
}