import { ChargebeeSubscription } from './interfaces'
import { apiNotifySlack, SlackAppUrls } from '../../core/slack/api'
import { buildCBAddons, buildCBPlanId } from './utils'
import { CBSalonType, CBSubscriptionType, PLAN_ID_TO_COUPON_ID_MAP } from './constants'
import { Scale } from '../../data/signup/interfaces'
import { getPlanPrice, getScalesPrice } from '../../data/signup/utils'
import { delay, has } from 'lodash'
import { getLocalStorageItem } from '../../core/localStorage'
import { generatePath } from 'react-router-dom'
import { buildUtmQueryParamString, UtmParams } from '../utm'
import { GrinParams } from '../grin/utils'
import { grinConversion } from '../grin/service'
import { ROUTES } from "../../appRoutes";

import { getEnvConfig } from "../../config";

declare var Chargebee: any

export const InitializeChargebee = () => {
  // ref - https://www.chargebee.com/checkout-portal-docs/api.html#chargebee-object
  const localStorageEnv = getLocalStorageItem('env')
  const config = getEnvConfig()
  if (localStorageEnv === 'staging') {
    const siteId = 'salonscale-test'
    typeof Chargebee !== 'undefined' && Chargebee.init && Chargebee.init({ site: siteId, enableGTMTracking: true, iframeOnly: true })
  } else {
    const siteId = config.chargebeeEnv
    typeof Chargebee !== 'undefined' && Chargebee.init && Chargebee.init({ site: siteId, enableGTMTracking: true, iframeOnly: true })
  }
}

// ref - https://www.chargebee.com/checkout-portal-docs/dropIn.html
// ref - https://www.chargebee.com/checkout-portal-docs/api.html
// ref - https://www.chargebee.com/checkout-portal-docs/drop-in-tutorial.html
// ref - https://www.chargebee.com/checkout-portal-docs/cart-api-ref.html
// ref - https://www.chargebee.com/checkout-portal-docs/product-api-ref.html#setcustomdata setCustomData on a subscription
// the portal this opens can be configured in chargebee
// https://salonscale-test.chargebee.com/checkout_and_portal_settings/fields
// this will redirect to /home when a subscription is created successfully
export const launchChargebeePortal = (props: {
  salonId: number
  token: string
  userId: number
  email: string
  firstName: string
  lastName: string
  phone: string
  salonscaleCustomerId: number
  salonType: CBSalonType
  subscriptionType: CBSubscriptionType
  freeScale: Scale
  scaleQuantities: { [key: string]: number }
  heardAboutSalonscale: string | null
  heardAboutSalonscaleExtraDetails: string | null
  grinParams: GrinParams
  utmParams: UtmParams
  isSpecialPricing: boolean
  canSeeScales: boolean
}) => {
  const {
    token,
    userId,
    salonId,
    email,
    firstName,
    lastName,
    phone,
    salonscaleCustomerId,
    salonType,
    subscriptionType,
    freeScale,
    scaleQuantities,
    heardAboutSalonscale,
    heardAboutSalonscaleExtraDetails,
    utmParams,
    grinParams,
    isSpecialPricing,
    canSeeScales
  } = props

  try {
    const scalesPrice = getScalesPrice(scaleQuantities, isSpecialPricing)
    const hasScales = scalesPrice > 0
    const cbPlanId = buildCBPlanId(salonType, subscriptionType, hasScales)
    const hasGrin = has(grinParams, 'platform') && grinParams.platform === 'grin'

    // const hasFreeScale = canSeeScales && (subscriptionType === 'yearly' || subscriptionType === 'perfectpairing')
    // everyone gets a free scale now
    const hasFreeScale = canSeeScales
    const cbAddons = canSeeScales ? buildCBAddons(scaleQuantities, hasFreeScale, freeScale) : []
    const couponMap = PLAN_ID_TO_COUPON_ID_MAP(isSpecialPricing, hasGrin)
    const cbCouponIds = has(couponMap, cbPlanId) ? couponMap[cbPlanId] : []
    const planPrice = getPlanPrice(salonType, subscriptionType, isSpecialPricing)
    const chargebeeRequest: any = {
      planId: cbPlanId,
      addons: cbAddons,
      couponIds: cbCouponIds,
      customer: {
        // prefilling customer details
        // id: '', // optional chargebee customer id
        email: email.includes('+') ? '' : email, // this doesnt like johnmason+test123@salonscale.com
        first_name: firstName,
        last_name: lastName,
        phone: phone,
        billing_address: {
          first_name: firstName,
          last_name: lastName,
        },
        // custom fields can be added here
        cf_salonscale_customer_id: salonscaleCustomerId,
        cf_heard_about_salonscale: heardAboutSalonscale,
        cf_heard_about_salonscale_extra_details: heardAboutSalonscaleExtraDetails,
        cf_utm_term: utmParams.term || null,
        cf_utm_source: utmParams.source || null,
        cf_utm_medium: utmParams.medium || null,
        cf_utm_content: utmParams.content || null,
        cf_utm_campaign: utmParams.campaign || null,
      },
    }
    createChargeBeeSubscription({
      token,
      userId,
      salonId,
      request: chargebeeRequest,
      utmParams,
      grinTotal: planPrice,
      hasGrin,
    })
  } catch (err) {
    apiNotifySlack({
      title: 'Signup Error',
      subtitle: `User: ${email}`,
      content: `Error: ${JSON.stringify(err)}`,
      slackAppUrl: SlackAppUrls.ngerrors,
      color: '#FF004B',
    })
  }
}

const createChargeBeeSubscription = (params: {
  token: string
  userId: number
  salonId: number
  request: ChargebeeSubscription
  utmParams: UtmParams
  grinTotal: number
  hasGrin: boolean
}): any => {
  const { hasGrin, userId, salonId, grinTotal, request, utmParams } = params
  const cbInstance = Chargebee.getInstance()
  const cart = cbInstance.getCart()

  const product = cbInstance.initializeProduct(request.planId)
  product.setCustomData({cf_salon_id: salonId})

  request.addons.forEach((addon) => {
    product.addAddon(addon)
  })

  request.couponIds.forEach((couponId) => {
    product.addCoupon(couponId)
  })

  cart.setCustomer(request.customer)
  cart.replaceProduct(product)
  // https://www.chargebee.com/checkout-portal-docs/dropIn.html#setcheckoutcallbacks
  cbInstance.setCheckoutCallbacks((checkoutCart: any) => {
    return {
      loaded: () => {
        // handle checkout loaded
      },
      close: () => {
        // handle checkout closed
      },
      success: (hostedPageId: { id: string }) => {
        if (hasGrin) {
          grinConversion({ amount: grinTotal, userId, planId: request.planId })
        }
        // delay this function call to ensure grin conversion goes through before changing the window location
        delay(() => {
          const utmQueryParams = buildUtmQueryParamString(utmParams)
          window.location.href = `${generatePath(ROUTES.signupSubscriptionRedirect)}?${utmQueryParams}`
        }, 500)
      },
      step: (value: string) => {
        // value -> which step in checkout
      },
    }
  })

  cart.proceedToCheckout()
}

export const manageChargebeeSubscription = () => {
  // ref - https://www.chargebee.com/checkout-portal-docs/api.html#chargebee-instance-object
  const cbInstance = Chargebee.getInstance()
  const cbPortal = cbInstance.createChargebeePortal()
  cbPortal.open({
    close() {
      // callbacks if we ever need them
    },
  })
}
