import {
  Box,
  Button,
  Circle,
  Flex,
  NumberDecrementStepper,
  NumberIncrementStepper,
  NumberInput,
  NumberInputField,
  NumberInputStepper,
  Text,
} from '@chakra-ui/react'
import React, { useEffect } from 'react'
import { UseViewSize } from '../../../core/UseViewSize'
import { ImgCarousel } from '../../../mini-lib/img-carousel/ImgCarousel'
import { MaterialIcon } from '../../../mini-lib/icons/MaterialIcon'
import { SignupSelectedItems, SignupTotal } from './SignupCart'
import { useAppSelector } from '../../../hooks'
import {
  reduceAvailableScales,
  reduceSetScaleQuantity,
  reduceSetSelectedFreeScale,
  selectAvailableScales,
  selectScaleQuantities,
  selectSelectedFreeScale,
} from '../../../data/signup/slice'
import { getScalePrice } from '../../../data/signup/utils'
import { Scale } from '../../../data/signup/interfaces'
import { useDispatch } from 'react-redux'
import { generatePath, Link } from 'react-router-dom'
import { TopNav } from '../../../mini-lib/top-nav/TopNav'
import { UseBuildSignupQueryParams, UseInitializeSignupState } from './signupHooks'
import { GetAvailableScales } from "../../../mini-lib/flags/PermanentFlags";
import { COLORS } from "../../../mini-lib/theme/colors";
import { ReleaseSpecialPricing } from '../../../mini-lib/flags/Release'
import { ROUTES } from "../../../appRoutes";

export const AccessoryPage = (props: {}) => {
  UseInitializeSignupState()
  const dispatch = useDispatch()
  const { isDesktop, isMobile } = UseViewSize()
  // const subscriptionType = useAppSelector(selectSubscriptionType)
  const availableScales = GetAvailableScales()
  const carouselImgUrls = availableScales.map((scale) => scale.imgUrl)
  const releaseSpecialPricing = ReleaseSpecialPricing()

  useEffect(() => {
      dispatch(reduceAvailableScales(availableScales))
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, availableScales.length])

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])


  return (
    <Box>
      <TopNav />
      {isMobile && (
        <Box p="12px">
          <Link
            to={{ pathname: generatePath(ROUTES.signupPlans), search: UseBuildSignupQueryParams() }}
            className="cy-back"
          >
            <Text color="shades.neutral.500">{'<'} Back to Plans</Text>
          </Link>
        </Box>
      )}
      <Flex flexWrap="wrap-reverse" p="0 12px" justify={isDesktop ? 'start' : 'center'}>
        {/* left column */}
        <Flex w={isDesktop ? '50%' : '100%'} direction="column" align="center">
          {!isMobile && (
            <>
              <Box h="48px" />
              <Link
                to={{ pathname: generatePath(ROUTES.signupPlans), search: UseBuildSignupQueryParams() }}
                className="cy-back"
              >
                <Text color="shades.neutral.500" w="500px">
                  {'<'} Back to Plans
                </Text>
              </Link>
            </>
          )}
          <ImgCarousel
            imgUrls={carouselImgUrls}
            showPreviewImgs={true}
            imgHeight={isMobile ? '150px' : '250px'}
            imgWidth="50%"
          >
            <Text width="100%" textAlign="left" fontSize="18px" fontWeight="bold">
              About our Bluetooth Scales
            </Text>
            <Box>
              <Text variant="footnote">
                With the latest responses technology, our scale enables you to be the artist you are!
              </Text>
              <Box h="12px" />
              <Text variant="footnote">
                By calculating the cost of hair color in your bowl in real-time, you are empowered to color your way
                knowing everything leaving the color bar is paid for.
              </Text>
              <Box h="8px" />
              <Flex align="center">
                <MaterialIcon size="8px" name="circle" m="0 12px" />
                <Text variant="footnote">Bluetooth enabled with our mobile app</Text>
              </Flex>
              <Flex align="center">
                <MaterialIcon size="8px" name="circle" m="0 12px" />
                <Text variant="footnote">Powered by 4 AAA batteries</Text>
              </Flex>
              <Flex align="center">
                <MaterialIcon size="8px" name="circle" m="0 12px" />
                <Text variant="footnote">Free worldwide shipping included</Text>
              </Flex>
            </Box>
          </ImgCarousel>
        </Flex>
        {/* right column */}
        <Flex w={isDesktop ? '50%' : '100%'} direction="column" maxW="500px">
          <Box h={isMobile ? '12px' : '48px'} />
          <Text variant={isMobile ? 'giganticsubtitle' : 'gigantictext'} fontWeight="bold" color={releaseSpecialPricing ? 'black' : "brand.peach.300"}>
            Accessorize
          </Text>
          <Box h="24px" />
          <SignupSelectedItems includeDescription={releaseSpecialPricing}/>

          {/* scale content*/}
          {/*{(subscriptionType === 'yearly' || subscriptionType === 'perfectpairing') && <ChooseFreeScale />}*/}
          {/* everyone gets to see free scales now */}
          <ChooseFreeScale />

          <Box h="48px" />
          <Text variant="title1" fontWeight="bold">
            add accessories
          </Text>
          <Text fontStyle='italic'>*scales only ship to USA and Canada</Text>
          <Box h="12px" />
          <ChooseScaleAddons />

          <Box h="48px" />
          <SignupTotal />

          <Box h="24px" />
          <Link
            to={{ pathname: generatePath(ROUTES.signupCheckout), search: UseBuildSignupQueryParams() }}
            className="cy-next"
          >
            <Button w="100%" variant="round-large" background={releaseSpecialPricing ? 'brand.lavender.500' : `brand.peach.300`} color='white'>
              review order and pay
            </Button>
          </Link>
          <Box h="24px" />
        </Flex>
      </Flex>
    </Box>
  )
}

export const ChooseFreeScale = () => {
  const dispatch = useDispatch()
  const { isMobile } = UseViewSize()
  const selectedScale = useAppSelector(selectSelectedFreeScale)
  const availableScales = useAppSelector(selectAvailableScales)
  const releaseSpecialPricing = ReleaseSpecialPricing()

  const scaleChange = (scale: Scale) => {
    dispatch(reduceSetSelectedFreeScale(scale))
  }

  return (
    <Box>
      <Box h="48px" />
      <Text variant="title1" fontWeight="bold">
        choose your free scale
      </Text>
      <Text fontStyle='italic'>*scales only ship to USA and Canada</Text>
      <Box h="12px" />
      <Box
        w="100%"
        maxW="500px"
        p={isMobile ? '24px 12px' : '24px'}
        borderColor="shades.neutral.200"
        borderWidth="1px"
        borderRadius="15px"
      >
        <Flex justify="space-between" align="center">
          <Flex align="center" gridGap={isMobile ? '6px' : '24px'}>
            <MaterialIcon name="radio_button_checked" size="24px" />
            <Text variant={isMobile ? 'title3' : 'title2'} fontWeight="regular" textTransform="uppercase">
              bluetooth scale
            </Text>
          </Flex>

          <Flex align="center">
            <Text
              variant={isMobile ? 'title3' : 'title2'}
              textTransform="uppercase"
              color={COLORS.shades_neutral_400}
              textDecoration={'line-through'}
            >
              ${getScalePrice(releaseSpecialPricing)}
            </Text>
            <Text ml="4px" variant="title2">
              FREE
            </Text>
          </Flex>
        </Flex>{' '}
        <Box>
          <Box h="12px" />
          <Text variant="headline">{selectedScale.label}</Text>
          <Box h="4px" />
          <Flex align="center" gridGap="12px">
            {availableScales.map((scale) => {
              return (
                <Flex
                  key={scale.id}
                  justify="center"
                  align="center"
                  cursor="pointer"
                  h="24px"
                  w="24px"
                  p="3px"
                  borderRadius="50%"
                  border={selectedScale.id === scale.id ? '1px solid black' : ''}
                  onClick={() => scaleChange(scale)}
                >
                  <Box borderRadius="50%" h="100%" w="100%" bg={scale.colorHex} />
                </Flex>
              )
            })}
          </Flex>
          {/*{selectedScale && selectedScale.id === LINEN_SCALE.id && <LinenScaleMessage/>}*/}
        </Box>
      </Box>
    </Box>
  )
}

export const ChooseScaleAddons = () => {
  const { isMobile } = UseViewSize()
  const scaleQuantities = useAppSelector(selectScaleQuantities)
  const availableScales = useAppSelector(selectAvailableScales)
  return (
    <Flex
      w="100%"
      p={isMobile ? '24px 12px' : '24px'}
      borderColor="shades.neutral.200"
      borderWidth="1px"
      borderRadius="15px"
      direction="column"
    >
      <Text variant="title2" fontWeight="regular" textTransform="uppercase">
        SALONSCALE BLUETOOTH SCALE
      </Text>
      <Box h="12px" />
      <Text variant="headline">Scales Retail at $60 USD each</Text>

      {availableScales.map((scale) => {
        return <ScaleOption key={scale.id} scale={scale} scaleQuantities={scaleQuantities} />
      })}
      {/*{scaleQuantities[LINEN_SCALE.id] > 0 && <LinenScaleMessage/>}*/}
    </Flex>
  )
}

export const ScaleOption = (props: { scale: Scale; scaleQuantities: { [key: string]: number } }) => {
  const dispatch = useDispatch()
  const { scale, scaleQuantities } = props
  const quantity = scaleQuantities[scale.id] || 0
  const scaleChange = (updatedQuantity: string) => {
    const quantityNumber = parseInt(updatedQuantity)
    if (isNaN(quantityNumber)) {
      dispatch(reduceSetScaleQuantity({ scale, quantity: 0 }))
    } else {
      dispatch(reduceSetScaleQuantity({ scale, quantity: quantityNumber }))
    }
  }
  const releaseSpecialPricing = ReleaseSpecialPricing()
  const price = getScalePrice(releaseSpecialPricing)
  return (
    <Flex align="center" justify="space-between" mt="24px">
      <Flex align="center">
        <Circle size="32px" bg={scale.colorHex} mr="24px" />
        <Box>
          {scale.label}
          <NumberInput size="lg" maxW={32} value={quantity} min={0} onChange={scaleChange}>
            <NumberInputField />
            <NumberInputStepper>
              <NumberIncrementStepper />
              <NumberDecrementStepper />
            </NumberInputStepper>
          </NumberInput>
        </Box>
      </Flex>
      <Flex align="center">
        <Text variant="title3">${quantity * price}</Text>
      </Flex>
    </Flex>
  )
}

export const LinenScaleMessage = () => {
  return (
    <Flex pt='12px' gridGap='4px'>
      <MaterialIcon colorhex={COLORS.warning} name='info_outlined'/>
      Linen scales available in USA only
    </Flex>
  )
}
