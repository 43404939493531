import { APIColorLara, ColorLara } from '../products/interfaces'
import { DropdownOption } from "../../mini-lib/filters/DropdownFilter";

// external interfaces
//
//

// export type APIBowlTypes =
//   | string
//   | 'base' // name: 'Base'
//   | 'highlight' // name: 'Highlight'
//   | 'toner' // name: 'Toner'
//   | 'root' // name: 'Root'
//   | 'shadow-root' // name: 'Shadow Root'
//   | 'high-lift' // name: 'High Lift'
//   | 'treatment' // name: 'Treatment'
//   | 'end' // name: 'End'
//   | 'lowlight' // name: 'Lowlight'
//   | 'base-break' // name: 'Base Break'
//   | 'base-bump' // name: 'Base Bump'
//   | 'foil' // name: 'Foil'
//   | 'mid' // name: 'Mid'
//   | 'extensions' // name: 'Extensions'
// export type APIMethodOfEntry = 'manual' | 'scale' | 'voice' | string

export type APIDisplayUnit = 'g' | 'oz' | string

export interface APIBowlType {
  bowltype_id?: number // this will come from lara but not from django
  id: string
  name: string
}

// internal interfaces
//
//
export type SessionStatus = 'open' | 'complete' | string
export type DisplayUnit = 'g' | 'oz'

export interface BowlType {
  bowltypeId?: number // this will come from lara but not from django
  id: string
  name: string
}

export type ProductTargetFilter = 'hasTarget' | 'noTarget' | null | string
export const ProductTargetFilterOptions: DropdownOption[] = [
  {label: 'All Targets', value: ''},
  {label: 'Has target', value: 'hasTarget'},
  {label: 'No target', value: 'noTarget'},
]

export type ProductOnHandFilter = 'hasOnHand' | 'noOnHand' | null | string
export const ProductOnHandFilterOptions: DropdownOption[] = [
  {label: 'All On-Hand', value: ''},
  {label: 'Counted', value: 'hasOnHand'},
  {label: 'Not Counted', value: 'noOnHand'},
]

export type ProductPricingFilter = 'hasPricing' | 'noPricing' | null | string
export const ProductPricingFilterOptions: DropdownOption[] = [
  {label: 'All Pricing', value: ''},
  {label: 'With Pricing', value: 'hasPricing'},
  {label: 'No Pricing', value: 'noPricing'},
]


// lara session interfaces
//
//
//
//
//
export interface APISessionMetaCreateLara {
  user_id: number,
  assistant_id?: number,
  client_id: number
  notes?: string,
  date: string // 2023-06-14T06:00:00+00:00
}

export interface APISessionMetaPatchLara {
  user_id?: number,
  user_assistant_id?: number,
  client_id?: number
  notes?: string,
  date?: string // 2023-06-14T06:00:00+00:00
}

export interface APISessionSupplyLara {
  id: number,
  name: string,
  price: number,
  quantity: number,
  supply: {
    id: number,
    name: string,
    price: number
  }
}

export interface APICreateOrUpdateSessionSupplyLara {
  id?: number
  supply_id: number
  quantity: number
}

export interface APISessionExtensionLara {
  id: number;
  session_id: number;
  product: APIColorLara;
  salon_purchase_price_per_unit: number;
  client_purchase_price_per_unit: number;
  quantity: string;
}

export interface APIBowlTypeLara {
  bowltype_id: number,
  id: string,
  name: string
}

export interface APISessionColorLara {
  id: number;
  session_id: number;
  product: APIColorLara;
  salon_purchase_price_per_gram: number;
  client_purchase_price_per_gram: number;
  bowl_type: APIBowlTypeLara | null
  bowl_nickname: string | null
  amount_grams: string;
  target_amount_grams: null | string;
  waste_grams: string | null;
  bowl: number;
  method_of_entry: string;
  display_unit: APIDisplayUnit
  client_total_cents: number
  waste_total_cents: number
}

export interface APISessionPhotoLara {
  id: number
  client_id: number | null
  session_id: number
  session_date: string
  photo_url: string
  photo_tag: string // 'before' | 'after'
  storage_object: string
}
export interface APICreateSessionPhotoLara {
  photo_url: string
  photo_tag: string // 'before' | 'after'
  storage_object: string
}

export interface APICreateOrUpdateSessionExtensionLara {
  id?: number
  product_id: number
  quantity: number
}

export interface APICreateOrUpdateSessionColorLara {
  id?: number
  product_id: number
  amount_grams: number
  display_unit?: APIDisplayUnit
  waste_grams?: number
  bowl?: number
  bowl_nickname: string | null

  // possibly unneeded
  amount?: number
  waste?: number
  method_of_entry?: string
  bowl_type_id?: number | null
}

export interface APISessionMetaLara {
  id: number
  client: {
    id: number
    first_name: string
    last_name: string
    middle_name: string
    phone: string
    session_count: null | number
    note: null | string
  }
  date: string
  date_in_tz: string
  notes: string
  user: {
    id: number
    user_id: number
    full_name: string
    first_name: string
    last_name: string
    user_photo_url?: string
  }
  assistant: {
    id: number
    user_id: number
    full_name: string
    first_name: string
    last_name: string
    user_photo_url?: string
  } | null
  status: null | string
  completed_date: string
  completed_date_in_tz: null | string

  /** @deprecated use `total_client_color_items_cents` instead */
  total_client_purchase_price_session_color_items: number
  /** @deprecated use `total_client_purchase_price_cents` instead */
  total_client_purchase_price: number

  total_client_purchase_price_session_extension_items: number
  total_client_purchase_price_session_labor_items: number
  total_client_purchase_price_session_supply: number

  total_color_items_waste_cents: number
  total_client_color_items_cents: number
  total_client_purchase_price_cents: number
  integration_contexts: {
    square_booking: string | null
  }
}

export interface SessionMetaLara {
  id: number
  client: {
    id: number
    firstName: string
    lastName: string
    middleName: string
    phone: string
    sessionCount: null | number
    note: null | string
  }
  date: Date
  dateInTz: string
  notes: string
  user: {
    id: number
    userId: number
    fullName: string
    firstName: string
    lastName: string
    photoUrl: string
  }
  assistant: {
    id: number
    userId: number
    fullName: string
    firstName: string
    lastName: string
    photoUrl: string
  } | null
  status: null | string
  completedDate: string
  completedDateInTz: null | string
  totalClientPurchasePriceSessionColorItemsCents: number
  totalClientPurchasePriceSessionExtensionItemsCents: number
  totalClientPurchasePriceSessionLaborItemsCents: number
  totalClientPurchasePriceSessionSupplyCents: number
  totalClientPurchasePriceCents: number

  totalColorItemsWasteCents: number
  squareBooking: string | null
}

export interface SessionSupplyLara {
  id: number,
  name: string,
  priceCents: number,
  quantity: number,
  supply: {
    id: number,
    name: string,
    priceCents: number
  }
}

export interface SessionExtensionLara {
  id: number
  sessionId: number
  product: ColorLara
  salonPurchasePrice: number
  clientPurchasePrice: number
  quantity: number
}

export interface BowlTypeLara {
  bowltypeId: number,
  id: string,
  name: string
}

export interface SessionColorLara {
  id: number
  sessionId: number
  product: ColorLara
  salonPurchasePrice: number
  clientPurchasePrice: number
  bowlType: BowlTypeLara | null
  bowlNickname?: string | null
  amountGrams: number
  targetAmountGrams: null | number
  wasteGrams: number | null
  clientTotalPriceCents: number
  wasteTotalPriceCents: number
  bowl: number
  methodOfEntry: string
  displayUnit: DisplayUnit | string
}

export interface SessionPhotoLara {
  id: number
  clientId: number | null
  sessionId: number
  sessionDate: string
  photoUrl: string
  photoTag: string // 'before' | 'after'
  storageObject: string
}
