import { ChargebeeAddon } from './interfaces'
import { CBSalonType, CBSubscriptionType } from './constants'
import { keys } from 'lodash'
import { Scale } from '../../data/signup/interfaces'

export function buildCBPlanId(salonType: CBSalonType, subscriptionType: CBSubscriptionType, hasScales: boolean) {
  if (subscriptionType === 'perfectpairing') {
    return `${salonType}-${subscriptionType}-usd`
  }

  // special case for trial plans
  if (subscriptionType === 'monthly' && !hasScales) {
    return `${salonType}-trial-usd`
  }

  // special case for bundle plans
  if (subscriptionType === 'yearly') {
    return `${salonType}-bundle-usd`
  }

  // addon or setup plans
  return `${salonType}-addons-monthly-usd`
}

export function buildCBScaleAddonId(scaleType: string, isFree = false): string {
  // black has to be special cased
  const normalizedScaleType = scaleType === 'black' ? '' : `-${scaleType}`
  const normalizedIsFree = isFree ? '-free' : ''
  return `salonscale-bluetooth-scale${normalizedIsFree}${normalizedScaleType}-usd`
}

export const buildCBAddons = (
  scaleQuantities: { [key: string]: number },
  hasFreeScale: boolean,
  freeScale: Scale,
): ChargebeeAddon[] => {
  const addons: ChargebeeAddon[] = []
  if (hasFreeScale) {
    const cbScaleAddonId = buildCBScaleAddonId(freeScale.id, true)
    addons.push({ id: cbScaleAddonId, quantity: 1 })
  }
  keys(scaleQuantities).forEach((scaleId) => {
    const cbScaleAddonId = buildCBScaleAddonId(scaleId, false)
    const quantity = scaleQuantities[scaleId]
    if (quantity > 0) {
      addons.push({ id: cbScaleAddonId, quantity })
    }
  })
  return addons
}
