import { APISessionMetaPatchLara, SessionMetaLara } from '../../data/sessions/interfaces'
import { UseViewSize } from '../../core/UseViewSize'
import { Box, Flex, Text } from '@chakra-ui/react'
import { DateInput } from '../../mini-lib/dates-and-times/DateInput'
import { StylistAutocompleteDeprecated } from '../stylists/StylistAutocompleteDeprecated'
import { ClientAutocomplete } from '../clients/ClientAutocomplete'
import React, { useEffect } from 'react'
import { buildLaraServerDateTime } from "../../core/dates";
import { SESSION_STATUS_OPTIONS } from "../../data/sessions/constants";
import { useAppSelector } from "../../hooks";
import { SalonMember } from "../../data/user/interfaces";
import { selectMemberList } from "../../data/user/slice";
import { find } from "lodash";
import { dispatchListSalonMembers } from "../../data/user/api";
import { useDispatch } from "react-redux";
import { UseBaseApiParams } from "../../core/UseBaseApiParams";
import { Loading } from "../../mini-lib/loading/Loading";

export const SessionMetaDetails = (props: {sessionMeta: SessionMetaLara | null, patchSessionMeta: (props: APISessionMetaPatchLara) => void }) => {
  const { isMobile } = UseViewSize()
  const dispatch = useDispatch()
  const {sessionMeta, patchSessionMeta} = props
  const {user, salonId} = UseBaseApiParams()
  const models = useAppSelector(selectMemberList)
  const initialStylist: SalonMember | null | any = sessionMeta && sessionMeta.user && models ? find(models, { userId: sessionMeta.user.userId }) || null : null
  const initialAssistant: SalonMember | null | any = sessionMeta && sessionMeta.assistant && models ? find(models, { userId: sessionMeta.assistant.userId }) || null : null
  const date = sessionMeta ? new Date(sessionMeta.date) : null

  useEffect(() => {
    dispatch(dispatchListSalonMembers(user.token, salonId))
  }, [dispatch, user.token, salonId])

  return (
    <>
      <Text variant="title3">Session Details</Text>
      <Box h="24px" />
      <Flex align="center" gridGap="12px" flexWrap="wrap">
        <Box w={isMobile ? '45%' : '165px'}>
          <DateInput
            label='Date'
            placeholder='Date'
            disabled={sessionMeta?.status === SESSION_STATUS_OPTIONS.complete}
            value={date}
            onChange={(date) => {
              // updateSession({ date: date.toISOString(), completed_date: date.toISOString() })
              patchSessionMeta({ date: buildLaraServerDateTime(date) })
            }}
            isClearable={false}
            onKeyDown={(e) => {
              e.preventDefault();
           }}
          />
        </Box>
        <Box w={isMobile ? '45%' : '165px'}>
          {initialStylist ? (
            <StylistAutocompleteDeprecated
              label='Stylist'
              disabled={sessionMeta?.status === SESSION_STATUS_OPTIONS.complete}
              isClearable={false}
              initialStylist={initialStylist}
              onSelect={(stylist) => {
                // updateSession({ stylist_id: stylist.stylistId, stylist_user_id: stylist.userId })
                patchSessionMeta({ user_id: stylist.userId })
              }}
            />
          ) : <Loading/>}
        </Box>
        <Box w={isMobile ? '45%' : '165px'}>
          {sessionMeta?.client && (
            <ClientAutocomplete
              label='Client'
              disabled={sessionMeta?.status === SESSION_STATUS_OPTIONS.complete}
              isClearable={false}
              initialClient={sessionMeta?.client}
              onSelect={(client) => {
                // updateSession({ client_id: client.id })
                patchSessionMeta({ client_id: client.id })
              }}
            />
          )}
        </Box>
          <Box w={isMobile ? '45%' : '165px'}>
        {(initialAssistant || initialStylist) ? (
            <StylistAutocompleteDeprecated
              label='Assistant'
              disabled={sessionMeta?.status === SESSION_STATUS_OPTIONS.complete}
              isClearable={false}
              initialStylist={initialAssistant}
              onSelect={(stylist) => {
                // updateSession({ assistant_user_id: stylist.userId })
                patchSessionMeta({ user_assistant_id: stylist.userId })
              }}
            />
           ) : <Loading/>
        }
          </Box>
      </Flex>
    </>
  )
}
