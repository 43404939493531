/////////////// api.ts
//
//

import axios, {AxiosResponse} from 'axios'

import {
  APILaborItem,
  APILaborService,
  APILaborServiceCreate,
  APILaborServiceUpdate,
  APILaborTier,
  APILaborTierCreate,
  APILaborTierServiceCreate,
  APILaborTierServiceUpdate,
  APILaborTierUpdate,
  LaborItem,
  LaborService,
  LaborTier,
  LaborTierService,
} from './interfaces'
import {
  mapAPILaborItemsToLaborTierServices,
  mapAPILaborServicesToLaborServices,
  mapAPILaborTiersToLaborTiers,
} from './mappers'

import { GetServerBaseUrl } from '../../env'


// labor item apis
//
//
export const apiListLaborItems = (params: {
  token: string,
  salonId: number,
  pageNumber? : number,
  searchText? : string,
}): Promise<LaborTierService[]> => {
  const {
    token,
    salonId,
    pageNumber = 1,
    // searchText = '',
  } = params
  const url = `${GetServerBaseUrl()}/labor-items/?token=${token}&salon_id=${salonId}&page=${pageNumber}`
  return axios
    .get(url)
    .then((response: AxiosResponse<{ data: APILaborItem[] }>) => {
      return mapAPILaborItemsToLaborTierServices(response.data.data)
    })
    .catch((error) => {
      throw error
    })
}

export const apiUpdateLaborItems = (params: {
  token: string
  userId: number
  salonId: number
  models: APILaborTierServiceUpdate[]
}): Promise<LaborTierService[]> => {
  const { token, userId, salonId, models } = params
  const url = `${GetServerBaseUrl()}/labor-items/?token=${token}&user_id=${userId}&salon_id=${salonId}`
  const body = {
    token,
    owner_id: userId,
    salonId: salonId,
    labor_items: models,
  }
  return axios
    .put(url, body)
    .then((response: any) => {
      return mapAPILaborItemsToLaborTierServices(response.data.data)
    })
    .catch((error) => {
      throw error
    })
}
export const apiCreateLaborItems = (params: {
  token: string
  userId: number
  salonId: number
  models: APILaborTierServiceCreate[]
}): Promise<LaborTierService[]> => {
  const { token, userId, salonId, models } = params
  const url = `${GetServerBaseUrl()}/labor-items/?token=${token}&salon_id=${salonId}&user_id=${userId}`
  const body = {
    token,
    owner_id: userId,
    salonId: salonId,
    labor_items: models,
  }
  return axios
    .post(url, body)
    .then((response: any) => {
      return mapAPILaborItemsToLaborTierServices(response.data.data)
    })
    .catch((error) => {
      throw error
    })
}
export const apiDeleteLaborTierServices = (params: {
  token: string
  userId: number
  salonId: number
  models: LaborItem[]
}): Promise<any> => {
  const { token, userId, salonId, models } = params
  const modelIds = models.map((model) => model.id).join(',')
  const url = `${GetServerBaseUrl()}/labor-items/?token=${token}&salon_id=${salonId}&user_id=${userId}&ids=${modelIds}`
  return axios
    .delete(url)
    .then((response: any) => {
      return models
    })
    .catch((error) => {
      throw error
    })
}

// labor tier apis
//
//
export const apiListLaborTiers = (params: {token: string, salonId: number, pageNumber?: number}): Promise<LaborTier[]> => {
  const {token, salonId, pageNumber = 1} = params
  const url = `${GetServerBaseUrl()}/labor-tiers/?token=${token}&salon_id=${salonId}&page=${pageNumber}`
  return axios
    .get(url)
    .then((response: AxiosResponse<{ data: APILaborTier[] }>) => {
      return mapAPILaborTiersToLaborTiers(response.data.data)
    })
    .catch((error) => {
      throw error
    })
}

export const apiUpdateLaborTiers = (params: {
  token: string
  userId: number
  salonId: number
  models: APILaborTierUpdate[]
}): Promise<LaborTier[]> => {
  const { token, userId, salonId, models } = params
  const url = `${GetServerBaseUrl()}/labor-tiers/?token=${token}&user_id=${userId}&salon_id=${salonId}`
  const body = {
    token,
    owner_id: userId,
    salonId: salonId,
    labor_tiers: models,
  }
  return axios
    .put(url, body)
    .then((response: any) => {
      return mapAPILaborTiersToLaborTiers(response.data.data)
    })
    .catch((error) => {
      throw error
    })
}
export const apiCreateLaborTiers = (params: {
  token: string
  userId: number
  salonId: number
  models: APILaborTierCreate[]
}): Promise<LaborTier[]> => {
  const { token, userId, salonId, models } = params
  const url = `${GetServerBaseUrl()}/labor-tiers/?token=${token}&salon_id=${salonId}&user_id=${userId}`
  const body = {
    token,
    owner_id: userId,
    salonId: salonId,
    labor_tiers: models,
  }
  return axios
    .post(url, body)
    .then((response: any) => {
      return mapAPILaborTiersToLaborTiers(response.data.data)
    })
    .catch((error) => {
      throw error
    })
}
export const apiDeleteLaborTiers = (params: {
  token: string
  userId: number
  salonId: number
  models: LaborTier[]
}): Promise<any> => {
  const { token, userId, salonId, models } = params
  const modelIds = models.map((model) => model.id).join(',')
  const url = `${GetServerBaseUrl()}/labor-tiers/?token=${token}&salon_id=${salonId}&user_id=${userId}&ids=${modelIds}`
  return axios
    .delete(url)
    .then((response: any) => {
      return models
    })
    .catch((error) => {
      throw error
    })
}

// labor service apis
//
//
export const apiListLaborServices = (params: {token: string, salonId: number, pageNumber?: number}): Promise<LaborService[]> => {
  const {token, salonId, pageNumber = 1} = params
  const url = `${GetServerBaseUrl()}/labor-services/?token=${token}&salon_id=${salonId}&page=${pageNumber}`
  return axios
    .get(url)
    .then((response: AxiosResponse<{ data: APILaborService[] }>) => {
      return mapAPILaborServicesToLaborServices(response.data.data)
    })
    .catch((error) => {
      throw error
    })
}

export const apiUpdateLaborServices = (params: {
  token: string
  userId: number
  salonId: number
  models: APILaborServiceUpdate[]
}): Promise<LaborService[]> => {
  const { token, userId, salonId, models } = params
  const url = `${GetServerBaseUrl()}/labor-services/?token=${token}&user_id=${userId}&salon_id=${salonId}`
  const body = {
    token,
    owner_id: userId,
    salonId: salonId,
    labor_services: models,
  }
  return axios
    .put(url, body)
    .then((response: any) => {
      return mapAPILaborServicesToLaborServices(response.data.data)
    })
    .catch((error) => {
      throw error
    })
}
export const apiCreateLaborServices = (params: {
  token: string
  userId: number
  salonId: number
  models: APILaborServiceCreate[]
}): Promise<LaborService[]> => {
  const { token, userId, salonId, models } = params
  const url = `${GetServerBaseUrl()}/labor-services/?token=${token}&salon_id=${salonId}&user_id=${userId}`
  const body = {
    salonId: salonId,
    labor_services: models,
  }
  return axios
    .post(url, body)
    .then((response: any) => {
      return mapAPILaborServicesToLaborServices(response.data.data)
    })
    .catch((error) => {
      throw error
    })
}
export const apiDeleteLaborServices = (params: {
  token: string
  userId: number
  salonId: number
  models: LaborService[]
}): Promise<any> => {
  const { token, userId, salonId, models } = params
  const modelIds = models.map((model) => model.id).join(',')
  const url = `${GetServerBaseUrl()}/labor-services/?token=${token}&salon_id=${salonId}&user_id=${userId}&ids=${modelIds}`
  return axios
    .delete(url)
    .then((response: any) => {
      return models
    })
    .catch((error) => {
      throw error
    })
}