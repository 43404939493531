import {
  Avatar,
  AvatarGroup,
  Box,
  Button,
  Flex,
  Input,
  InputGroup,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverCloseButton,
  PopoverContent,
  PopoverTrigger,
  Text,
} from '@chakra-ui/react'
import { MaterialIcon } from '../../mini-lib/icons/MaterialIcon'
import React, { useState } from 'react'
import { APISalonUserTierUpsert } from '../../data/user/interfaces'
import { useDispatch } from 'react-redux'
import { useAppSelector } from '../../hooks'
import { LaborTier } from '../../data/labor/interfaces'
import {
  dispatchDeleteSalonUserTiers,
  dispatchUpdateSalonUserTiers,
} from '../../data/user/api'
import { UseViewSize } from '../../core/UseViewSize'
import { UseBaseApiParams } from "../../core/UseBaseApiParams";
import { selectSalonUsers } from "../../data/salon-user/slice";
import { SalonUser } from "../../data/salon-user/interfaces";
import { filterSalonUsers } from "../../data/salon-user/utils";
import { difference } from "lodash";
import { COLORS } from "../../mini-lib/theme/colors";
import { CHECKLIST_CODES } from "../../data/start-guide/constants";
import { HelpPopover } from "../start-guide/common-components/HelpPopover";
import { teamLaborOnboardSteps } from "../../data/labor/constants";

export const LaborTierUsers = (props: { tier: LaborTier, tierIndex: number, categoryIndex: number }) => {
  const { tier, tierIndex, categoryIndex } = props
  const salonUsers = useAppSelector(selectSalonUsers)
  const dispatch = useDispatch()
  const {user, salonId} = UseBaseApiParams()
  const [searchText, setSearchText] = useState('')
  const { isMobile } = UseViewSize()

  const removeFromTier = (salonUsers: SalonUser) => {

    if (salonUsers) {
      dispatch(
        dispatchDeleteSalonUserTiers({ token: user.token, userId: user.userId, salonId: salonId, models: [salonUsers] }),
      )
    }
  }
  const addToTier = (member: SalonUser) => {
    const models: APISalonUserTierUpsert[] = [{ user_id: member.userId, tier_id: tier.id }]
    if (user) {
      dispatch(dispatchUpdateSalonUserTiers({ token: user.token, userId: user.userId, salonId: salonId, models: models }))
    }
  }
  const salonUsersInTier: SalonUser[] | null = salonUsers
    ? filterSalonUsers({users: salonUsers, filters: {searchText: null, tierId: tier.id, roleId: null}})
    : null

  const salonUsersNotInTier: SalonUser[] | null = salonUsers
    ? difference(salonUsers, salonUsersInTier || [])
    : null
  const filteredSalonUsersNotInTier: SalonUser[]  | null = salonUsersNotInTier
    ? filterSalonUsers({users: salonUsersNotInTier, filters: {searchText, tierId: null, roleId: null}})
    : null


  const helpIndex = categoryIndex === 0 && tierIndex === 0 ? teamLaborOnboardSteps.manageUserTiers : -1

  return (
    <Popover>
      <PopoverTrigger>
        <Box>
          <HelpPopover description={'Click here to assign or remove stylists from this labor level'} stepIndex={helpIndex} guideTypes={[CHECKLIST_CODES.addLaborTeam]}>
            <AvatarGroup size="xs" max={2} cursor="pointer" spacing="-6px">
              {!isMobile &&
                salonUsersInTier?.map((salonUser) => {
                  return (
                    <Avatar
                      key={salonUser.userId}
                      border='none'
                      size="sm"
                      mr="8px"
                      bg="brand.skylight.200"
                      color="brand.skylight.500"
                      name={`${salonUser.firstName} ${salonUser.lastName}`}
                      src={salonUser.userPhotoUrl}
                    />
                  )
                })}
              {(isMobile || salonUsersInTier?.length === 0) && <SalonUserTierIcon />}
            </AvatarGroup>
          </HelpPopover>
        </Box>
      </PopoverTrigger>
      <PopoverContent>
        <PopoverArrow />
        <PopoverCloseButton />
        <PopoverBody>
          <Box h="24px" />
          {salonUsersInTier?.map((member) => {
            return (
              <Box
                cursor="pointer"
                key={member.userId}
                bg="brand.skylight.50"
                w="200px"
                mb="8px"
                borderRadius="50px"
                onClick={() => removeFromTier(member)}
              >
                <Flex align="center" justify="space-between" pr="4px">
                  <Flex align="center" isTruncated={true} maxW="185px">
                    <Avatar
                      border='none'
                      size="sm"
                      mr="8px"
                      bg="brand.skylight.200"
                      color="white"
                      name={`${member.firstName} ${member.lastName}`}
                      src={member.userPhotoUrl}
                    />
                    {member.firstName} {member.lastName}
                  </Flex>
                  <MaterialIcon name="close" color="brand.skylight.500" />
                </Flex>
              </Box>
            )
          })}
          <Box mt="24px">
            <Search searchText={searchText} setSearchText={setSearchText} />
            <Box mt="24px" p="0 12px" h="200px" overflowX="scroll">
              {filteredSalonUsersNotInTier?.map((salonUser) => {
                return (
                  <Flex justify="space-between" align="center" key={salonUser.userId}>
                    <Box isTruncated={true} maxW="145px" mb="8px">
                      <Box>
                        <Text>
                          {salonUser.firstName} {salonUser.lastName}
                        </Text>
                      </Box>
                      {salonUser.tierName && (
                        <Box fontSize="sm" color="shades.neutral.500">
                          {salonUser.tierName}
                        </Box>
                      )}
                      {!salonUser.tierName && (
                        <Box fontSize="sm" color="shades.neutral.500">
                          no tier
                        </Box>
                      )}
                    </Box>
                    <Flex align="center">
                      <Button variant="ghost" colorScheme="brand.skylight" onClick={() => addToTier(salonUser)}>
                        <MaterialIcon name="add_circle" style={{ marginRight: '4px' }} />
                        add
                      </Button>
                    </Flex>
                  </Flex>
                )
              })}
            </Box>
            {(!filteredSalonUsersNotInTier || filteredSalonUsersNotInTier.length === 0) && (
              <Flex m="12px 0" justify="center" color="brand.skylight.400">
                No Stylists found
              </Flex>
            )}
          </Box>
        </PopoverBody>
      </PopoverContent>
    </Popover>
  )
}

const Search = (props: { searchText: string; setSearchText: (string) => void }) => {
  const { searchText, setSearchText } = props
  return (
    <InputGroup>
      <Input
        onChange={(evt) => {
          setSearchText(evt.target.value)
        }}
        placeholder="Enter a name"
        value={searchText}
      />
    </InputGroup>
  )
}

export const SalonUserTierIcon = () => {
  return (
    <Flex justify='center' align='center' borderRadius='100px' p='2px' bg={COLORS.skylight_50} h='24px' w='24px'>
      <MaterialIcon
        name='person_add_alt'
        size='18px'
        colorhex={COLORS.skylight_500}
      />
    </Flex>
  )
}