import { Flex, Text } from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { SignupFormLayout } from "./SignupFormLayout";
import { Gap } from "../../../mini-lib/gap/Gap";
import { Loading } from "../../../mini-lib/loading/Loading";
import { useDispatch } from "react-redux";
import { UseBaseApiParams } from "../../../core/UseBaseApiParams";
import { generatePath, Redirect } from 'react-router-dom'
import { ROUTES } from '../../../appRoutes'

// note: this page is required for google tag conversion analytics
export const SignupTrialSuccessRedirect = () => {
  const [time, setTime] = useState(0)
  const dispatch = useDispatch()
  const {user} = UseBaseApiParams()
  const salonId = user.currentSalonContext?.salonId || -1

  // sync to pipedrive and then redirect
  useEffect(() => {
    const timer = setTimeout(() => {
        setTime(time + 1)
    }, 1000)
    return () => clearTimeout(timer)
  }, [dispatch, time, setTime])

  if (time > 1) {
    return <Redirect to={generatePath(ROUTES.trialIndex, { salonId })} />
  }

  return (
    <SignupFormLayout>
      <Flex justify='center' align='center' direction='column'>
        <Gap/>
        <Text variant='title2-desktop-bold'>Setting up your trial</Text>
        <Gap s='32px'/>
        <Loading/>
      </Flex>
    </SignupFormLayout>
  )
}